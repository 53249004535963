import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',  //首页
    redirect: '/home'
},
{
    path:'/home',  
    name:'home',   
    component:()=>import('@/views/Home/HomeView.vue')
},
{
    path:'/privacy',  
    name:'privacy',   
    component:()=>import('@/views/Privacy/Privacy.vue')
},
{
  path:'/alerts', 
  name:'alerts',   
  component:()=>import('@/views/Products/Alerts/Alerts.vue')
},
{
  path:'/dispute-intelligence', 
  name:'dispute-intelligence',   
  component:()=>import('@/views/Products/Dispute/DisputeIntelligence.vue')
},
{
  path:'/contact', 
  name:'contact',   
  component:()=>import('@/views/contact/ContactView.vue')
},
{
  path:'/ethoca-alerts', 
  name:'ethoca-alerts',   
  component:()=>import('@/views/Products/EthoceAlerts.vue')
},
{
  path:'/verifi-alerts', 
  name:'verifi-alerts',   
  component:()=>import('@/views/Products/Verifi.vue')
},
{
  path:'/digital-receipts', 
  name:'digital-receipts',   
  component:()=>import('@/views/Products/Digital/Digital.vue')
},
{
  path:'/white-label', 
  name:'white-label',   
  component:()=>import('@/views/Products/White/White.vue')
},
{
  path:'/compelling-evidence', 
  name:'compelling-evidence',   
  component:()=>import('@/views/Products/Compelling/Compelling.vue')
},
{
  path:'/chargebacks', 
  name:'chargebacks',   
  component:()=>import('@/views/Features/chargebacks/Chargebacks.vue')
},
{
  path:'/reason', 
  name:'reason',   
  component:()=>import('@/views/Features/Reason/reason.vue')
},
{
  path:'/developer', 
  name:'developer',   
  component:()=>import('@/views/Developer/developer.vue')
},
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
