<template> 
  
  <router-view/>
  
</template>

<script>

</script>

<style>
body,html{
  margin: 0;
  font-family: 'Microsoft YaHei';
}
</style>
